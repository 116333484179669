<template>
  <div id="mui-player11" style="margin-left: 5%" @click="play">ssss</div>
</template>

<script>

import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'
import globals from '../../public/js/global'
import _ from "lodash";

export default {
  name: "PlayVideo",
  data() {
    return {
      loadType: 'mp4',
      playerConfig: {}, // 播放器配置
    }
  },

  methods: {
    play(url, title) {
      url = 'https://zjyzs.wynca.com.cn:7902/tool/download?encryptfile=jXpTVtXXwK7dSvqXzLc%2F7DuejOPIR0n%2F&ext=mp4';
      if (_.isEmpty(url)) {
        return;
      }
      // url = url.replace('/tool/download', '/tool/downloadVideo');
      var config = {
        container: '#mui-player11',
        src: url,
        title: '',
        autoplay: false,
        autoFit: true,
        live: false,
        objectFit: 'contain',
        height: '90%',
        width: '90%',
        themeColor: 'rgb(62, 175, 124)',
        videoAttribute: [
          {attrKey: 'webkit-playsinline', attrValue: ''},
          {attrKey: 'playsinline', attrValue: ''},
          {attrKey: 'x5-playsinline', attrValue: ''},
          {attrKey: 't7-video-player-type', attrValue: 'inline'},
          {attrKey: 'x5-video-player-type', attrValue: 'h5-page'},
          {attrKey: 'x-webkit-airplay', attrValue: 'allow'},
          {attrKey: 'controlslist', attrValue: 'nodownload'},
        ],
        plugins: [
          typeof MuiPlayerDesktopPlugin != 'undefined' ? new MuiPlayerDesktopPlugin({}) : {},
        ],
      }
      this.playerConfig = Object.assign({}, config);
      // this.playerConfig['height'] = window.innerHeight-50;
      // this.playerConfig['width'] = '90%;
      this.playerConfig['lang'] = 'zh-cn';
      this.playerConfig['title'] = '安全视频';

      let muiplayer = new MuiPlayer(this.playerConfig);
      globals.mp = muiplayer;
    },
  },
  created() {
    var url = this.$route.params.url;
    var title = this.$route.params.title;
    this.play(url, title);
    setTimeout(this.play(url, title), 1500)
  },
  beforeUnmount() {
    if (_.isFunction(globals.mp.destory)) {
      globals.mp.destory()
    }
  },
}
</script>

<style scoped>

</style>